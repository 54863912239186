 import axios from '@/utils/request'

 // /**
 //  * 登录日志
 //  * @param query
 //  * @returns {AxiosPromise}
 //  */
 // export function listLoginLog (query) {
 //   console.log(query, 'query')
 //   return axios({
 //     url: '/log/listLoginLog',
 //     method: 'get',
 //     data: query
 //   })
 // }
 // /**
 //  * 操作日志
 //  * @param query
 //  * @returns {AxiosPromise}
 //  */
 // export function listOperLog (query) {
 //   console.log(query, 'query')
 //   return axios({
 //     url: '/log/listOperLog',
 //     method: 'get',
 //     params: query
 //   })
 // }
 /**
  * 分页查询系统访问记录
  *
  * @author taidi
  * @date 2021/02/23 09:29
  */
 export function logPage (parameter) {
   return axios({
     url: '/log/listLoginLog',
     method: 'get',
     params: parameter
   })
 }
