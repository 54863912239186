<template>
  <a-modal
    title="系统访问记录"
    :width="900"
    :visible="visible"
    okText="确认"
    :closable="false"
    :confirmLoading="confirmLoading"
    :cancelButtonProps="{style: {display: 'none'}}"
    @ok="handleCancel">

    <a-spin :spinning="confirmLoading">
      <a-row :gutter="48">
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              编号：
            </span>
            {{ form.logId }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              用户ID：
            </span>
            {{ form.userId }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              用户账号：
            </span>
            {{ form.userName }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              登录IP地址：
            </span>
            {{ form.ipAddr }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              调用次数：
            </span>
            {{ form.callCount }}
          </p>
        </a-col>
        <a-col :md="24" :sm="24">
          <p>
            <span class="title">
              提示信息：
            </span>
            {{ form.msg }}
          </p>
        </a-col>
        <a-col :md="24" :sm="24">
          <p>
            <span class="title">
              访问时间：
            </span>
            {{ moment(form.timeStamp).format("YYYY-MM-DD hh:mm:ss") }}
          </p>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>

  export default {
    data () {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 15 }
        },
        visible: false,
        confirmLoading: false,
        form: {}
      }
    },
    methods: {
      edit (record) {
        this.visible = true
        setTimeout(() => {
          this.form = {
            id: record.id,
            logId: record.logId,
            userId: record.userId,
            userName: record.userName,
            ipAddr: record.ipAddr,
            callCount: record.callCount,
            status: record.status,
            msg: record.msg,
            timeStamp: record.timeStamp
          }
        }, 100)
      },
      handleCancel () {
        this.form = {}
        this.visible = false
      }
    }
  }
</script>
<style scoped>
.title{
  font-weight: bold;
}
</style>
