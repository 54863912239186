<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">

          <a-col :md="8" :sm="24">
            <a-form-item label="用户名">
              <a-input v-model="queryParam.userName" placeholder="请输入用户名"/>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24">
            <a-form-item label="描述">
              <a-input v-model="queryParam.msg" placeholder="请输入描述"/>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      :columns="columns"
      :data="loadData"
      :rowKey="(record) => record.id"
    >
      <span slot="timeStamp" slot-scope="timeStamp">
        {{ moment(timeStamp).format("YYYY-MM-DD hh:mm:ss") }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="$refs.editForm.edit(record)">详情</a>
        <a-divider type="vertical"/>
        <a>订阅警告</a>
      </span>
    </s-table>
    <edit-form ref="editForm" @ok="handleOk"/>

  </a-card>
</template>

<script>
  import { STable } from '@/components'
  import { logPage } from '@/api/system/log'
  import editForm from './acLoginLogEditForm'

  export default {
    components: {
      STable,
      editForm
    },

    data () {
      return {
        advanced: false, // 高级搜索 展开/关闭
        queryParam: {
          // 当前页数
          pageNum: 1,
          //每页条数
          pageSize: 10
        }, // 查询参数
        columns: [ // 表头
          {
            title: '用户名',
            dataIndex: 'userName'
          },
          {
            title: '描述',
            dataIndex: 'msg'
          },
          {
            title: '登录地址',
            dataIndex: 'ipAddr'
          },
          {
            title: '更新时间',
            dataIndex: 'timeStamp',
            scopedSlots: { customRender: 'timeStamp' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' }
          }
        ],
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          // 分页 pageNum 当前页 pageSize当前页大小
          this.queryParam = Object.assign(this.queryParam,{pageNum: parameter.pageNo,pageSize: parameter.pageSize})
          return logPage(this.queryParam).then((res) => {
            if (res.code === 200) {
              res.data = res['rows']
              res.totalCount = res['total']
              // 当前页
              res.pageNo =this.queryParam.pageNum
              return res
            } else {
              res.data = []
              res.totalCount = res.pageNo = 0
              // res.totalCount = 0
              return res
            }
          })
        }
      }
    },

    methods: {
      toggleAdvanced () {
        this.advanced = !this.advanced
      },
      /**
       *打开编辑数据
       */
      handleOk () {
        this.$refs.table.refresh()
      }
    }
  }
</script>

<style lang="less">
.table-operator {
  margin-bottom: 18px;
}
button {
  margin-right: 8px;
}
.status-info{
  position: relative;
}
.top{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(22, 144, 255);
  position: absolute;
  top: 9px;
  left: -11px
}
.out{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(243, 34, 45);
  position: absolute;
  top: 9px;
  left: -11px
}
</style>
